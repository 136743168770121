import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    // fontSize: 24,
    border: '1px solid black'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: '1px solid black'
  }
}));

function createData(name, value, fontSize, color) {
  return { name, value, fontSize, color };
}

const MuiTable = ({
  pieceNumber = '',
  pieceName = '',
  estateTax = 0,
  estateTaxValue = 0,
  pursuitRatio = 0,
  estatePrice = 0,
  pursuitTax = 0,
  total = 0
}) => {
  const rows = [
    createData('رقم البند', pieceNumber, 52, 'rgba(76, 188, 142, 1)'),
    createData('اسم البند', pieceName, 52, 'rgba(76, 188, 142, 1)'),
    createData('سعر البيع', estatePrice, 52, 'rgba(76, 188, 142, 1)'),
    createData(
      `ضريبة القيمة المضافة ${estateTax}% من سعر البيع`,
      estateTaxValue,
      52,
      'rgba(76, 188, 142, 1)'
    ),
    createData(
      `عمولة السعي ${pursuitRatio}% من سعر البيع`,
      pursuitTax,
      52,
      'rgba(76, 188, 142, 1)'
    ),
    createData('المبلغ الاجمالي', total, 74, 'red')
  ];
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell
                sx={{
                  fontSize: row?.fontSize,
                  bgcolor: row?.color,
                  color: 'white',
                  fontWeight: 600
                }}
                align="right">
                {row.name}
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  fontSize: row?.fontSize,
                  bgcolor: row?.color,
                  color: 'white',
                  fontWeight: 700
                }}
                align="center">
                {row.value}
              </StyledTableCell>
            </StyledTableRow>
          ))}
          {/* <StyledTableRow sx={{ border: '1px solid black' }}>
            <StyledTableCell colSpan={2} sx={{ fontWeight: 700, fontSize: 72 }} align="center">
              {total}
            </StyledTableCell>
          </StyledTableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default MuiTable;
