/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import HomeContext from '../../contexts/home-context/HomeProvider';
import DocTitleScrollTop from '../../utils/DocTitleScrollTop';
import { LoadingOutlined } from '@ant-design/icons';
import congImg from '../../assets/imgs/cong.jpg';

import './HomePage.scss';
import HomeSlider from './HomeSlider';
import DisabledModal from '../../common/disabled-modal/DisabledModal';
import MuiTable from '../../common/material-ui-components/MuiTable';
// import Fade from 'react-reveal/Fade';

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  let splitArray = formetedNumber.split('.');
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const HomePage = () => {
  DocTitleScrollTop();
  // const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const {
    setFetchCount,
    allFeatures,
    fetchCount,
    congratsModalOpened,
    setCongratsModalOpened,
    loadingHome
  } = useContext(HomeContext);
  console.log(allFeatures);
  const mainContent = () => {
    return (
      <div className="picae-contanier">
        <div className="pice-data">
          <MuiTable
            pieceNumber={allFeatures?.data?.piece_number}
            pieceName={allFeatures?.data?.name}
            estatePrice={formatNumber(parseFloat(allFeatures?.data.estate_price))}
            estateTax={formatNumber(parseFloat(allFeatures?.data.estate_tax))}
            estateTaxValue={formatNumber(parseFloat(allFeatures?.data.estate_tax_value))}
            pursuitRatio={formatNumber(parseFloat(allFeatures?.data.pursuit_ratio))}
            pursuitTax={formatNumber(parseFloat(allFeatures?.data.pursuit_tax))}
            total={formatNumber(parseFloat(allFeatures?.data.total))}
          />
          <div className="pice-data-desc">
            {/* <div
              className="info-first"
              style={{
                backgroundColor: '#2c3a47',
                borderTopRightRadius: 12
              }}>
              رقم البند : {allFeatures?.data?.name ? allFeatures?.data?.name : ''}
            </div>
            <div
              className="info-first"
              style={{
                backgroundColor: '#2c3a47',
                borderTopLeftRadius: 12
              }}>
              إسم البند <br></br>
              {allFeatures?.data?.piece_number ? allFeatures?.data?.piece_number : ''}
            </div> */}

            {/* <div className="info-title">مساحة الأرض بالمتر المربع</div>
            <div className="info-data">
              {allFeatures?.data?.estate_area ? parseFloat(allFeatures?.data?.estate_area) : ''}
            </div> */}

            {/* <div className="info-title">سعر المتر المربع</div>
            <div className="info-data">
              {allFeatures?.data?.meter_price
                ? formatNumber(parseFloat(allFeatures?.data?.meter_price))
                : ''}
            </div> */}

            {/* {allFeatures?.data?.estate_price ? (
              <>
                <div className="info-title info-cell">السعر الصافى</div>
                <div className="info-data info-cell">
                  {formatNumber(parseFloat(allFeatures?.data.estate_price))}
                </div>
              </>
            ) : null} */}

            {/* <div className="info-title">
              ضريبة التصرفات العقارية{' '}
              {allFeatures?.data?.estate_tax ? `( ${allFeatures?.data.estate_tax + ' %'} )` : ''}
            </div>
            <div className="info-data">
              {allFeatures?.data?.estate_tax_value
                ? formatNumber(parseFloat(allFeatures?.data.estate_tax_value))
                : null}
            </div> */}

            {/* <div className="info-title">
              نسبة السعي{' '}
              {allFeatures?.data?.pursuit_ratio
                ? `( ${allFeatures?.data.pursuit_ratio + ' %'} )`
                : ''}
            </div>
            <div className="info-data">
              {allFeatures?.data?.pursuit_tax
                ? formatNumber(parseFloat(allFeatures?.data.pursuit_tax))
                : null}
            </div> */}

            {/* <div className="info-title">
              القيمة المضافه{' '}
              {allFeatures?.data?.estate_tax ? `( ${allFeatures?.estate_tax + ' %'} )` : ''}
            </div>
            <div className="info-data">
              {allFeatures?.data?.estate_tax_value
                ? parseFloat(allFeatures?.estate_tax_value)
                : null}
            </div> */}
          </div>
          {/* {allFeatures?.data?.total && (
            <div className="total">
              {allFeatures?.data.total ? formatNumber(parseFloat(allFeatures?.data.total)) : ''}
            </div>
          )} */}
        </div>
        {/* <HomeSlider /> */}
      </div>
    );
  };

  const timeoutFunc = () => {
    setCongratsModalOpened(false);
  };

  useEffect(() => {
    if (allFeatures?.status == 2) {
      setCongratsModalOpened(true);
      setFetchCount(0);
      setTimeout(() => {
        timeoutFunc();
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutFunc);
    };
  }, [fetchCount, allFeatures?.status]);

  // console.log(images);
  const renderContent = () => {
    if (loadingHome && fetchCount === 0) {
      return mainContent();
      // <div className="custom-container">
      //   <div
      //     style={{
      //       display: 'grid',
      //       placeItems: 'center',
      //       minHeight: 300
      //     }}>
      //     <LoadingOutlined />
      //   </div>
      // </div>
    } else if (congratsModalOpened && fetchCount > 0) {
      return (
        <DisabledModal>
          <div className="congrates-wrap">
            <img src={congImg} alt="Congratulations" />
            <h1>مبروك</h1>
          </div>
        </DisabledModal>
      );
    }

    return mainContent();
  };

  return (
    // <Fade delay={50}>
    <div className="custom-container">
      <div className="home-page">
        <Helmet>
          <title>mazad</title>
        </Helmet>
        {renderContent()}
      </div>
    </div>
    // </Fade>
  );
};

export default HomePage;
